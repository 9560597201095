import React from "react";
import "../../assets/scss/layouts/_mainContent.scss";
import {
  cssIcon,
  gitIcon,
  githubIcon,
  htmlIcon,
  javascriptIcon,
  nodejsIcon,
  reactIcon,
  reduxIcon,
  sassIcon,
  tailwindIcon,
  typescriptIcon,
  firebaseIcon,
  mongodbIcon,
  bootstrapIcon,
} from "../../assets/skills-icons";
import {
  argentbank,
  billed,
  booki,
  fisheye,
  gameon,
  kasa,
  lespetitsplats,
  ohmyfood,
  sportsee,
  wealth,
} from "../../assets/oc-projects";
import {spaceDanger} from "../../assets/perso";

const MainContent: React.FC = () => {
  return (
    <div className="main-content">
      {/* section 1 : skills */}
      <section className="section skills">
        <p>Skills</p>
        <div className="background-icons">
          <div className="icon-container">
            <img className="icon-img" src={htmlIcon} alt="icon html" />
            <span>HTML</span>
          </div>
          <div className="icon-container">
            <img className="icon-img" src={cssIcon} alt="icon css" />
            <span>CSS</span>
          </div>
          <div className="icon-container">
            <img
              className="icon-img"
              src={javascriptIcon}
              alt="icon javascript"
            />
            <span>Javascript</span>
          </div>
          <div className="icon-container">
            <img
              className="icon-img"
              src={typescriptIcon}
              alt="icon typescript"
            />
            <span>Typescript</span>
          </div>
          <div className="icon-container">
            <img className="icon-img" src={reactIcon} alt="icon react" />
            <span>React</span>
          </div>
          <div className="icon-container">
            <img className="icon-img" src={reduxIcon} alt="icon redux" />
            <span>Redux</span>
          </div>
          <div className="icon-container">
            <img className="icon-img" src={sassIcon} alt="icon sass" />
            <span>Sass</span>
          </div>
          <div className="icon-container">
            <img className="icon-img" src={tailwindIcon} alt="icon tailwind" />
            <span>Tailwind</span>
          </div>
          <div className="icon-container">
            <img
              className="icon-img"
              src={bootstrapIcon}
              alt="icon bootstrap"
            />
            <span>Bootstrap</span>
          </div>
          <div className="icon-container">
            <img className="icon-img" src={nodejsIcon} alt="icon node" />
            <span>Node JS</span>
          </div>
          <div className="icon-container">
            <img className="icon-img" src={gitIcon} alt="icon git" />
            <span>Git</span>
          </div>
          <div className="icon-container">
            <img className="icon-img" src={githubIcon} alt="icon github" />
            <span>Github</span>
          </div>
          <div className="icon-container">
            <img className="icon-img" src={firebaseIcon} alt="icon firebase" />
            <span>Firebase</span>
          </div>
          <div className="icon-container">
            <img className="icon-img" src={mongodbIcon} alt="icon mongodb" />
            <span>MongoDB</span>
          </div>
        </div>
      </section>
      {/* section 3 : Projet OpenClassrooms */}
      <section className="section open-classrooms-projects">
        <p id="oc-projects">OpenClassrooms project</p>
        <div className="grid">
          <a
            href="https://github.com/Lisitius/P2_Booki_OC"
            target="_blank"
            rel="noopener noreferrer">
            <img className="project-image" src={booki} alt="Projet 2 : Booki" />
            <p>Booki</p>
          </a>
          <a
            href="https://github.com/Lisitius/P3_OhMyFood_OC"
            target="_blank"
            rel="noopener noreferrer">
            <img
              className="project-image"
              src={ohmyfood}
              alt="Projet 3 Ohmyfood"
            />
            <p>OhMyFood</p>
          </a>
          <a
            href="https://github.com/Lisitius/P4_GameOn_OC"
            target="_blank"
            rel="noopener noreferrer">
            <img
              className="project-image"
              src={gameon}
              alt="Projet 4 Game On"
            />
            <p>GameOn</p>
          </a>
          <a
            href="https://github.com/Lisitius/P6_FishEye_OC"
            target="_blank"
            rel="noopener noreferrer">
            <img
              className="project-image"
              src={fisheye}
              alt="Projet 6 FishEye"
            />
            <p>FishEye</p>
          </a>
          <a
            href="https://github.com/Lisitius/P7_LesPetitsPlats_OC"
            target="_blank"
            rel="noopener noreferrer">
            <img
              className="project-image"
              src={lespetitsplats}
              alt="Projet 7 Les petits plats"
            />
            <p>Les petits plats</p>
          </a>
          <a
            href="https://github.com/Lisitius/P9_Billed"
            target="_blank"
            rel="noopener noreferrer">
            <img className="project-image" src={billed} alt="Projet 9 billed" />
            <p>Billed</p>
          </a>
          <a
            href="https://github.com/Lisitius/P11_Kasa"
            target="_blank"
            rel="noopener noreferrer">
            <img className="project-image" src={kasa} alt="Projet 11 Kasa" />
            <p>Kasa</p>
          </a>
          <a
            href="https://github.com/Lisitius/P12_SportSee"
            target="_blank"
            rel="noopener noreferrer">
            <img
              className="project-image"
              src={sportsee}
              alt="Projet 12 SportSee"
            />
            <p>SportSee</p>
          </a>
          <a
            href="https://github.com/Lisitius/P13_ArgentBank"
            target="_blank"
            rel="noopener noreferrer">
            <img
              className="project-image"
              src={argentbank}
              alt="Projet 13 : argent bank"
            />
            <p>Argent Bank</p>
          </a>
          <a
            href="https://github.com/Lisitius/P14_WealthHealth"
            target="_blank"
            rel="noopener noreferrer">
            <img
              className="project-image"
              src={wealth}
              alt="Projet 14 wealth health"
            />
            <p>Wealth Health</p>
          </a>
        </div>
      </section>
      {/* section 3 : Projet OpenClassrooms */}
      <section className="section open-classrooms-projects">
        <p id="oc-projects">Personal project</p>
        <div className="grid">
          <a
            href="https://github.com/Lisitius/Space_Danger"
            target="_blank"
            rel="noopener noreferrer">
            <img
              className="project-image"
              src={spaceDanger}
              alt="Space Danger"
            />
            <p>Space Danger</p>
          </a>
        </div>
      </section>
    </div>
  );
};

export default MainContent;
