import React, {useState} from "react";
import "../../assets/scss/layouts/_sideBar.scss";
import emailIcon from "../../assets/icons/email-icon.png";
import githubIcon from "../../assets/icons/github-icon.png";
import LinkedInIcon from "../../assets/icons/linkedin-icon.png";
import avatar from "../../assets/avatar/avatar.jpg";
import CV from "../../assets/cv/CV.pdf";

const Sidebar: React.FC = () => {
  const [language, setLanguage] = useState("french");

  const toggleLanguage = (): void => {
    setLanguage(language === "french" ? "english" : "french");
  };

  return (
    <div className="sidebar">
      <div className="icons-bar">
        <a
          href="https://github.com/Lisitius"
          target="_blank"
          rel="noopener noreferrer">
          <img src={githubIcon} alt="GitHub Icon" />
        </a>
        <a
          href="https://www.linkedin.com/in/benjamin-fichaux/"
          target="_blank"
          rel="noopener noreferrer">
          <img src={LinkedInIcon} alt="LinkedIn Icon" />
        </a>
        <a
          href="mailto:benjamin.fichaux@gmail.com"
          target="_blank"
          rel="noopener noreferrer">
          <img src={emailIcon} alt="Email Icon" />
        </a>
      </div>
      <section className="section presentation">
        {language === "french" ? (
          <p className="title">
            Bonjour. Je suis <span className="highlight-name">Benjamin</span>,
            développeur React Frontend.
          </p>
        ) : (
          <p className="title">
            Hello. I&apos;m <span className="highlight-name">Benjamin</span>,
            frontend React developer.
          </p>
        )}
        <img className="avatar" src={avatar} alt="avatar" />
        {language === "french" ? (
          <p>
            Fasciné par la technologie depuis mon enfance, il y a deux ans,
            j&apos;ai entamé une reconversion professionnelle pour devenir
            développeur Frontend avec de forte notion de backend. Profondément
            attaché à la philosophie d&apos;un apprentissage continu tout au
            long de ma vie, je suis enthousiasmé par les nouveaux défis
            qu&apos;offre ce métier. Je suis aujourd&apos;hui à la recherche
            d&apos;une entreprise où je pourrais apporter ma perspective
            innovante et mon enthousiasme, une entreprise qui apprécie les défis
            autant que moi et qui se consacre à la création de projets
            passionnants.
          </p>
        ) : (
          <p>
            Fascinated by technology since my childhood, two years ago, I
            started a professional reconversion to become Frontend developer
            with a strong notion of backend. Deeply committed to the philosophy
            of continuous learning throughout Throughout my life, I am
            enthusiastic about new challenges what this job offers. I am looking
            today of a company where I could bring my perspective innovative and
            my enthusiasm, a company that appreciates challenges as much as me
            and who is dedicated to creating projects exciting.
          </p>
        )}
        <button onClick={toggleLanguage}>
          {language === "french" ? "English" : "Français"}
        </button>
        <div className="downloadCV">
          {language === "french" ? (
            <p className="cvText">
              Télécharger mon{" "}
              <a
                href={CV}
                download="Fichaux_Benjamin_CV"
                target="_blank"
                rel="noreferrer">
                <span className="cv">curiculum vitae (pdf)</span>
              </a>
            </p>
          ) : (
            <p className="cvText">
              Download my{" "}
              <a
                href={CV}
                download="Fichaux_Benjamin_CV"
                target="_blank"
                rel="noreferrer">
                <span className="cv">curiculum vitae (pdf)</span>
              </a>
            </p>
          )}
        </div>
      </section>
    </div>
  );
};

export default Sidebar;
