import React from "react";
import Sidebar from "../layouts/Sidebar";
import MainContent from "../layouts/MainContent";

const Home: React.FC = () => {
  return (
    <div className="home">
      <Sidebar />
      <MainContent />
    </div>
  );
};

export default Home;
